@use "../utils.scss" as utils;

$color-hidden: transparent;
$color-disable: #bbb;
$color-idle: #bbffbb;
$color-oncancel: #ffbbff;
$color-onbutton: #bbffff;
$color-onanchor: #ffffbb;

$duration: 1s;

#pointerMarkWrapper {
    position: fixed;
    z-index: 9000;
    pointer-events: none;
    user-select: none;
    top: -6px;
    left: -6px;
    width: 12px;
    height: 12px;
    transition: transform 0.1s linear;
}

#pointerMark {
    width: 12px;
    height: 12px;
    border-radius: 100px;
    opacity: 1;
    background-color: $color-hidden;
    transform: scale(1);
    animation: none;
    transition: transform 0.3s linear, opacity 0.3s linear,
        background-color 0.3s linear;

    &.status-99 {
        background-color: $color-hidden;
        opacity: 0;
    }
    &.status-0 {
        background-color: $color-disable;
    }
    &.status-1 {
        background-color: $color-idle;
    }
    &.status-2 {
        background-color: $color-oncancel;
        animation: activePointerAnime $duration linear 0.01s infinite;
    }
    &.status-3 {
        background-color: $color-onbutton;
        animation: activePointerAnime $duration linear 0.01s infinite;
    }
    &.status-4 {
        background-color: $color-onanchor;
        animation: activePointerAnime $duration linear 0.01s infinite;
    }
}

@keyframes activePointerAnime {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(5);
    }
}
