@use './utils.scss' as utils;

:root,
html,
body {
    font-size: 16px;
    font-family: "Noto Sans JP", sans-serif;
    color: utils.$color-base;
    line-height: 1.5;
    overflow: hidden;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

.is-pc {
    display: block;
    @include utils.tab {
        display: none;
    }
}

.is-sp {
    display: none;
    @include utils.tab {
        display: block;
    }
}
