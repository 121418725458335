@use '../../utils.scss' as utils;

.content {
    width: 100%;

    @include utils.tab {
        padding: 0 10px;
    }

    @include utils.sp {
        padding: 62px 6px 42px;
    }

    .image {
        margin-top: 1.5rem;
        width: 100%;
        height: 30vh;

        @include utils.sp {
            height: 100%;
        }

        img {
            display: block;
            margin: 0 auto;
            height: 100%;
            width: auto;
            max-width: 100%;
            object-fit: contain;

            @include utils.sp {
                width: 100%;
                height: auto;
                max-height: 30vh;
            }
        }
    }

    .description {
        margin-top: 1.2rem;
        line-height: 1.5;
        font-size: 0.875rem;
        text-align: justify;
        font-feature-settings: "palt";
    }

    .link {
        margin-top: 1.4rem;
        text-align: center;

        .link__button {
            display: inline-block;
            padding: 0.65em 2.25em;
            min-width: 180px;
            max-width: 270px;
            text-align: center;
            font-size: 0.875rem;
            border-radius: 100px;
            color: #fefefe;
            background-color: #262626;

            &:hover {
                background-color: #565656;
            }
        }
    }
}

.pc .content {
    .link {
        .link__button {
            @include utils.blinkingHover();
        }
    }
}

/* TypeVideo ----------------------------------- */
.content.type-video {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 100%;

    @include utils.tab {
        display: block;
    }

    .video-block {
        width: 55%;
        height: 30vh;
        max-height: 300px;

        @include utils.tab {
            margin: 0 auto;
            width: 70%;
        }

        @include utils.sp {
            width: 100%;
        }

        iframe {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .info-block {
        width: 42%;
        @include utils.tab {
            margin-top: 1rem;
            width: 100%;
        }
    }

    .link {
        text-align: left;
        @include utils.tab {
            text-align: center;
        }
    }
}
