@use '../utils.scss' as utils;

.site-logo {
    position: fixed;
    z-index: 500;
    left: 25px;
    top: 10px;
    width: 180px;
    height: 90px;

    @include utils.tab {
        top: 4px;
        left: 8px;
        width: 140px;
        height: 70px;
    }

    img {
        height: 100%;
        width: auto;
    }
}
