@use '../../utils.scss' as utils;

$darkPer: 20%;

$colorDefc: #eee;
$color1c: #ccf;
$color2c: #fcf;
$color3c: #fcc;
$color4c: #ffc;
$color5c: #cfc;
$color6c: #cff;
$color7c: #eee;

$colorDef: darken($colorDefc, $darkPer);
$color1: darken($color1c, $darkPer);
$color2: darken($color2c, $darkPer);
$color3: darken($color3c, $darkPer);
$color4: darken($color4c, $darkPer);
$color5: darken($color5c, $darkPer);
$color6: darken($color6c, $darkPer);
$color7: #a0a0a0;

.content-parts {
    /* Category ----------------------------------- */
    &.category {
        span {
            display: inline-block;
            margin-left: -1px;
            padding: 0.5em 1.2em;
            line-height: 1.5;
            font-size: 0.6125rem;
            color: utils.$color-black;
            background-color: $colorDefc;
            font-feature-settings: "palt";

            &.color-1 {
                background-color: $color1c;
            }
            &.color-2 {
                background-color: $color2c;
            }
            &.color-3 {
                background-color: $color3c;
            }
            &.color-4 {
                background-color: $color4c;
            }
            &.color-5 {
                background-color: $color5c;
            }
            &.color-6 {
                background-color: $color6c;
            }
            &.color-7 {
                background-color: $color7c;
            }
        }
    }
}

.content-parts {
    /* Title Summary ----------------------------------- */
    &.title-summary {
        position: relative;
        margin-top: 0.4rem;
        padding-left: 1.3rem;
        line-height: 1.5;
        font-feature-settings: "palt";

        &::before,
        &::after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 4px;
        }

        &::before {
            z-index: 2;
            height: 65%;
            background: $colorDef;
        }

        &::after {
            z-index: 1;
            height: 100%;
            background: $colorDef;
        }

        &.color-1::before {
            background-color: $color1;
        }
        &.color-1::before {
            background-color: $color1;
        }
        &.color-2::before {
            background-color: $color2;
        }
        &.color-3::before {
            background-color: $color3;
        }
        &.color-4::before {
            background-color: $color4;
        }
        &.color-5::before {
            background-color: $color5;
        }
        &.color-6::before {
            background-color: $color6;
        }
        &.color-7::before {
            background-color: $color7;
        }

        .title {
            font-size: 1.375rem;
        }

        .summary {
            font-size: 0.875rem;
        }
    }
}

/* Addition Button ----------------------------------- */
.content-parts {
    &.addition {
        margin-top: 0.8rem;
        font-size: 0.75rem;
        line-height: 1.5;
        text-align: justify;
        font-feature-settings: "palt";
    }
}

/* Close Button ----------------------------------- */
.content-parts {
    &.close-button {
        position: absolute;
        z-index: 10;
        right: -26px;
        top: -18px;
        width: 68px;
        height: 68px;
        background-color: #050505;
        border-radius: 100px;

        @include utils.tab {
            top: 0;
            right: 0;
            height: 56px;
            width: 56px;
            border-radius: 0 0 0 1px;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: calc(50% - 2px);
            left: 20%;
            width: 60%;
            height: 4px;
            border-radius: 100px;
            background-color: #bbb;
            transition: transform 0.5s;
        }

        &::before {
            transform: rotateZ(45deg);
        }
        &::after {
            transform: rotateZ(-45deg);
        }

        &:hover {
            background-color: #555;

            &::before {
                transform: rotateZ(135deg);
            }
            &::after {
                transform: rotateZ(45deg);
            }
        }

        /* WhiteButton */
        &--white {
            right: -10px;
            top: -10px;
            background-color: #f0f0f0;

            &::before,
            &::after {
                background-color: #050505;
            }

            &:hover {
                background-color: #bbb;
            }
        }
    }
}

.pc .content-parts {
    &.close-button {
        @include utils.blinkingHover();
    }
}

/* Matter Port View ----------------------------------- */
.matter-port-view {
    position: fixed;
    z-index: 1500;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    transition: opacity 490ms 10ms;
    opacity: 0;
    visibility: hidden;

    iframe {
        display: block;
        width: 100%;
        height: 100%;
    }

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
        visibility: visible;
        transition: opacity 490ms 10ms;
    }

    &.enter-done {
        opacity: 1;
        visibility: visible;
        transition: opacity 490ms 10ms;
    }
}
