@use '../utils.scss' as utils;

.mode-switcher {
    position: fixed;
    z-index: 500;
    left: 50%;
    bottom: 25px;
    width: 94%;
    max-width: 660px;
    border: 1px solid #cef7f2;
    border-radius: 4px;
    background: utils.$color-white;
    box-shadow: 0 0 1px 1px #cef7f2;
    transform: translate(-50%, 0);
    transition: transform 0.5s ease-out;

    &.is-opening {
        transform: translate(-50%, 100px);
    }

    &.is-opening-done {
        transform: translate(-50%, 0);
    }
}

.mode-switcher-inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
}

.mode-switch-button {
    display: block;
    width: 33.3333%;
    line-height: 1.8;
    font-size: 1.125rem;
    text-align: center;
    user-select: none;
    font-feature-settings: "palt";

    @include utils.tab {
        font-size: 1rem;
    }

    @include utils.sp {
        font-size: 0.875rem;
    }
}

.pc .mode-switch-button {
    @include utils.blinkingHover();

    &:hover {
        color: utils.$color-lightblack;
    }

    &:active {
        color: utils.$color-darkblack;
    }
}

#modeSwitchBg {
    position: absolute;
    z-index: -1;
    top: 3px;
    left: 3px;
    height: 75%;
    height: calc(100% - 6px);
    border-radius: 3px;
    background-color: #e7ffe7;
    transition: transform 0.5s;
}
