@use '../../utils.scss' as utils;

.content-layer {
    position: fixed;
    z-index: 700;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include utils.tab {
        display: block;
    }
}

.content-layer__inner {
    position: relative;
    width: 60%;
    max-width: 720px;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    background-color: utils.$color-white;
    box-shadow: 0 0 3px 3px rgba($color: #cccccc, $alpha: 0.7);

    @include utils.tab {
        width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 100%;
        border-radius: 0;
    }

    &.frame-type-2 {
        width: 80%;
        max-width: 820px;

        @include utils.tab {
            width: 100%;
            max-width: 100%;
        }
    }

    &.enter {
        transform: scale(0);
        opacity: 0;
        visibility: visible;
    }
    &.enter-active {
        transform: scale(1);
        transition: transform 500ms 100ms, opacity 500ms 100ms;
        opacity: 1;
        visibility: visible;
    }
    &.enter-done {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
        transition: transform 500ms 100ms, opacity 500ms 100ms;
    }
}

.content-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 28px 46px;
    width: 100%;
    height: 100%;
    max-height: 780px;
    overflow-y: auto;

    @include utils.tab {
        padding: 0;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }

    @include utils.sp {
        display: block;
    }
}
