@use '../../utils.scss' as utils;

$transition-duration: 300ms;

.standard-layout {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 110px 30px 90px 30px;
    opacity: 0;
    visibility: hidden;
    background-color: utils.$background_color-base;
    overflow: hidden;

    @include utils.tab {
        padding: 70px 0px 66px;
    }

    &.enter {
        visibility: visible;
        opacity: 0;
    }
    &.enter-active {
        opacity: 1;
        transition: opacity $transition-duration;
    }
    &.enter-done {
        opacity: 1;
        visibility: visible;
    }

    &.exit {
        opacity: 1;
        visibility: visible;
    }
    &.exit-active {
        opacity: 0;
        transition: opacity $transition-duration;
    }
    &.exit-done {
        opacity: 0;
        visibility: hidden;
    }
}

.standard-layout__inner {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
}

/* Standard Tabs ----------------------------------- */
.standard-tabs {
    width: 28%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;

    @include utils.sp {
        width: 35%;
    }
}

.standard-tabs__inner {
    width: 100%;

    .tab {
        display: block;
        padding: 1.3em 0.8em;
        width: 100%;
        line-height: 1.5;
        font-size: 0.8125rem;
        border-top: 1px solid #f5f5f5;
        color: utils.$color-black;
        font-feature-settings: "palt";

        @include utils.tab {
            padding: 0.8em 0.4em 0.8em 0.8em;
            font-size: 0.75rem;
        }

        &:first-child {
            border-top: 1px solid transparent;
        }

        &:hover {
            background-color: #edfded;
        }

        &.tab--link:hover {
            background-color: #edfded;
        }

        &.is-current {
            background-color: #f5f5f5;
            border-top: 1px solid transparent;
        }

        &a {
            display: block;
        }

        &.custom-kc {
            margin-top: 2.2em;
            border-top: none;

            @include utils.sp {
                margin-top: 1.5em;
            }
        }
    }

    .is-current + .tab {
        border-top: 1px solid transparent;
    }
}

.pc .standard-tabs__inner {
    .tab {
        transition: text-indent 0.3s;
        &:hover {
            color: utils.$color-lightblack;
            text-indent: 0.6em;
        }
        &:active {
            color: utils.$color-darkblack;
        }
    }
}

/* Standard List ----------------------------------- */
.standard-list {
    width: 72%;
    background-color: #f5f5f5;

    @include utils.sp {
        width: 65%;
    }
}

.standard-items {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.standard-item {
}

.standard-item:first-child {
    .standard-item__inner {
        padding-top: 8px;

        @include utils.tab {
            padding-top: 6px;
        }
    }
}

.standard-item:last-child {
    .standard-item__inner {
        padding-bottom: 8px;

        @include utils.tab {
            padding-bottom: 6px;
        }
    }
}

.standard-item__inner {
    display: flex;
    align-items: center;
    padding: 4px 10px;
    width: 100%;

    @include utils.tab {
        padding: 3px 5px;
    }

    @include utils.sp {
        padding: 3px 5px;
    }

    .image {
        width: 72px;
        height: 72px;
        border: 1px solid #e9eee9;
        flex-shrink: 0;
        border-radius: 5px;
        overflow: hidden;

        @include utils.tab {
            width: 64px;
            height: 64px;
        }

        @include utils.sp {
            width: 90px;
            height: 90px;
        }
    }

    .text-block {
        flex-grow: 1;
        width: calc(100% - 64px);
        padding-left: 14px;
        line-height: 1.5;
        color: utils.$color-black;
        font-feature-settings: "palt";

        @include utils.tab {
            padding-left: 12px;
        }

        @include utils.sp {
            padding-left: 6px;
        }

        .title {
            font-size: 1rem;
            @include utils.tab {
                font-size: 0.875rem;
            }
            @include utils.sp {
                font-size: 0.8125rem;
            }
        }

        .summary {
            font-size: 0.75rem;
            @include utils.tab {
                font-size: 0.6125rem;
            }
            @include utils.sp {
                font-size: 0.6125rem;
            }
        }
    }

    &:hover {
        background-color: #edfded;
    }
}

.pc .standard-item__inner {
    &:hover {
        .text-block {
            color: utils.$color-lightblack;
        }
    }

    &:active {
        .text-block {
            color: utils.$color-darkblack;
        }
    }
}

.tab-nav {
    width: 240px;
}
