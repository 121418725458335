@use "./utils.scss" as utils;

@use "./base.scss";

@use "./components/LoadingLayer.scss";

@use "./components/SiteLogo.scss";

@use "./components/HeaderButton.scss";

@use "./components/ModeSwitcher.scss";

@use "./components/ContentFrame/ContentParts";
@use "./components/ContentFrame/ContentLayer";
@use "./components/ContentFrame/ContentBackLayer";

@use "./components/ContentFrame/Content";

@use "./components/StandardLayout/StandardLayout";

@use "./components/PointerStalker.scss";

@use "./components/ThreeCanvas.scss";

.copyright {
    position: fixed;
    z-index: 1100;
    bottom: 3px;
    right: 15px;

    .copyright-text {
        font-size: 0.625rem;
        line-height: 1.5;
        color: #898296;
    }
}
