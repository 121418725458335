@use "../utils.scss" as utils;

.loading-layer {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: utils.$background_color-base;

    &.exit {
        opacity: 1;
        transform: scale(1);
    }

    &.exit-active {
        opacity: 0;
        transform: scale(2);
        transition: opacity 0.5s, transform 0.5s;
    }
}

.loading-layer-content {
    position: relative;
    width: 65%;
    max-width: 280px;
    transition: box-shadow 0.3s;

    &.is-loading {
        box-shadow: 0 0 3px 3px rgba($color: #ffbbbb, $alpha: 0.7);
    }

    &.is-loaded {
        box-shadow: 0 0 12px 12px rgba($color: #bbffbb, $alpha: 0.7);
        animation: loadingLayerContentIdling 1s ease 0.01s infinite alternate;

        &:hover {
            box-shadow: 0 0 18px 18px rgba($color: #bbffff, $alpha: 0.7);
        }
    }

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

@keyframes loadingLayerContentIdling {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-8px);
    }
}

.loading-bar-wrapper {
    position: absolute;
    z-index: 600;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    pointer-events: none;
    user-select: none;

    .loading-bar {
        position: absolute;
        background-color: utils.$background_color-base;
    }

    #loadingBar1 {
        top: -6px;
        left: 0;
        width: calc(100% + 4px);
        height: 8px;
        transform-origin: center right;
    }

    #loadingBar2 {
        top: 0;
        right: -6px;
        width: 8px;
        height: calc(100% + 4px);
        transform-origin: bottom center;
    }

    #loadingBar3 {
        bottom: -6px;
        right: 0;
        width: calc(100% + 4px);
        height: 8px;
        transform-origin: center left;
    }

    #loadingBar4 {
        bottom: 0;
        left: -6px;
        width: 8px;
        height: calc(100% + 4px);
        transform-origin: top center;
    }
}

.loading-content-button {
    position: absolute;
    z-index: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loading-logo {
    width: 82%;

    img {
        display: block;
    }
}

.loading-message {
    font-size: 1rem;
    margin-top: 15%;

    &.is-loading {
        animation: loadingMessageAnime 0.5s linear 0.01s infinite alternate;
    }
    &.is-loaded {
        animation: none;
    }
}

@keyframes loadingMessageAnime {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}
