@use '../utils.scss' as utils;

.header-button {
    position: fixed;
    z-index: 600;
    top: 24px;
    right: 26px;
    width: 60px;
    height: 60px;
    transition: opacity 0.3s 0.2s;

    @include utils.tab {
        top: 6px;
        right: 6px;
    }

    &.is-opening {
        opacity: 0;
    }

    &.is-opening-done {
        opacity: 1;
    }
}

.header-button__inner {
    width: 100%;
    height: 100%;

    .header-button-bar {
        position: absolute;
        top: calc(50% - 2px);
        left: calc(50% - 16px);
        width: 32px;
        height: 4px;
        border-radius: 100px;
        background-color: utils.$color-black;
        transition: transform 0.5s, visibility 0s 0s;
        box-shadow: 2px 1px 2px utils.$color-white;
    }

    #headerButtonBar1 {
        transform: translate(0, -10px) scale(1.3, 1);
    }
    #headerButtonBar2 {
        transform: translate(15%, 0) scale(1, 1);
    }
    #headerButtonBar3 {
        transform: translate(30%, 10px) scale(0.7, 1);
    }

    &:hover {
        #headerButtonBar1 {
            transform: translate(0, -10px) scale(1.3, 1);
        }
        #headerButtonBar2 {
            transform: translate(0, 0) scale(1.3, 1);
        }
        #headerButtonBar3 {
            transform: translate(0, 10px) scale(1.3, 1);
        }
    }
}

.pc .header-button__inner {
    transition: background-color 0.3s;

    @include utils.blinkingHover();

    &:hover {
        .header-button-bar {
            background-color: utils.$color-lightblack;
        }
        #headerButtonBar1 {
            transform: translate(0, -10px) scale(1.3, 1);
        }
        #headerButtonBar2 {
            transform: translate(0, 0) scale(1.3, 1);
        }
        #headerButtonBar3 {
            transform: translate(0, 10px) scale(1.3, 1);
        }
    }

    &:active {
        .header-button-bar {
            background-color: utils.$color-darkblack;
        }
    }
}

.header-button__inner.is-open {
    .header-button-bar {
        transition: transform 0.5s, opacity 0.3s, visibility 0s 1s;
    }

    #headerButtonBar1 {
        transform: translate(0, 0) scale(1.1, 1.1) rotateZ(225deg);
    }

    #headerButtonBar2 {
        opacity: 0;
        visibility: hidden;
        transform: translate(10%, 0) scale(0.5, 0.5) rotateZ(0);
    }

    #headerButtonBar3 {
        transform: translate(0, 0) scale(1.1, 1.1) rotateZ(-225deg);
    }
}

.pc .header-button__inner.is-open {
    &:hover {
        #headerButtonBar1 {
            transform: translate(0, 0) scale(1.1, 1.1) rotateZ(225deg);
        }

        #headerButtonBar2 {
            opacity: 0;
            visibility: hidden;
            transform: translate(10%, 0) scale(0.5, 0.5) rotateZ(0);
        }

        #headerButtonBar3 {
            transform: translate(0, 0) scale(1.1, 1.1) rotateZ(-225deg);
        }
    }
}
