.content-back-layer {
    position: fixed;
    z-index: 680;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: #ffffff;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    user-select: none;

    &.enter {
        opacity: 0;
    }
    &.enter-active {
        opacity: 0.8;
        visibility: visible;
        transition: opacity 500ms;
    }
    &.enter-done {
        opacity: 0.8;
        visibility: visible;
        transition: opacity 500ms;
    }
}
