/* Three Action buttons */
.three-action-buttons {
    position: fixed;
    right: 10px;
    top: 50vh;

    button {
        display: block;
        margin: 10px;
        padding: 0.5em 0.8em;
        background-color: #ddd;
    }
}
