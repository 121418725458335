$color-base: #505050;
$color-black: #505050;
$color-darkblack: #111111;
$color-lightblack: #909090;

$color-gray: #eee;

$color-white: #fefefe;
$color-lightwhite: #cccccc;

$color-greenyellow: #d9ffb1;

$color-red: #ffcccc;

$color-blue: #a1caff;

$background_color-base: #fefefe;
$background_color-white: #fefefe;

$pc: 901px; // PC
$tab: 900px; // タブレット
$sp: 560px; // スマホ

@mixin blinkingHover {
    &:hover {
        animation: blinking_light 64ms 2 cubic-bezier(0.33, 0.33, 0.75, 0.75);
    }
}

@keyframes blinking_light {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@mixin pc {
    @media (min-width: ($pc)) {
        @content;
    }
}

@mixin tab {
    @media (max-width: ($tab)) {
        @content;
    }
}

@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}
